import { Typography, Link } from '@aircall/tractor-v2';
import { FloatingWrapper } from '@components/LoginLayout/Login.styles';
import { SIGNUP_LINK_URL } from '@constants/urls.constants';
import { useTranslation } from 'react-i18next';

export function SignupButton() {
  const { t } = useTranslation();

  return (
    <FloatingWrapper gap='xxs' data-test='signup-link'>
      <Typography variant='bodyMediumS' color='text-secondary'>
        {t('signup.no_account')}
      </Typography>
      <Link href={SIGNUP_LINK_URL} data-test='signup-link' target='_blank'>
        <Typography variant='bodyMediumS' color='text-base'>
          {t('signup.create_account_link')}
        </Typography>
      </Link>
    </FloatingWrapper>
  );
}
